/* App.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}
p{text-decoration: none;}
h2{text-decoration: none;}
:focus {
  outline: none; /* or specify your custom focus styles */
}
.logo-container {
  display: flex;
  align-items: center;
}
.logo {
  position: absolute;
  top: 5px;
  font-size: 1.2rem;
  color: #0e2431;
  margin-right:  10px;
}
/* Υπάρχουσες κλάσεις Dark Mode */
body.dark-mode {
  /* Στυλ Dark Mode */
  background-color: #034aa6;
  background-image: linear-gradient(to right top, #051937, #002667, #0b2e97, #462dc4, #8312eb);
  
  background-size: cover;
  background-attachment: fixed;
}

.light-back {
  /* Στυλ Dark Mode */
  background-color: #fff;
}

/* Καινούριες κλάσεις Light Mode */
body.light-mode {
  /* Στυλ Light Mode */
  background-color: #F4D03F;
  background-image: linear-gradient(to right top, #051937, #00507f, #008f9c, #00cb79, #b1fb0a);

  
  background-attachment: fixed;
}

/* Κλάση για το Light Mode μόνο στο dropdown */
body.light-mode .section-dropdown {
  background-color: #111; /* Αλλάξτε το χρώμα του dropdown για το Light Mode */
}

/* Κλάση για το Light Mode μόνο στα links */
.body.light-mode a {
  color: #333; /* Αλλάξτε το χρώμα των links για το Light Mode */
}

/* Κλάση για το Light Mode μόνο στο dropdown υποσέλιδο */
body.light-mode .section-dropdown-sub {
  background-color: #111; /* Αλλάξτε το χρώμα του υποσέλιδου dropdown για το Light Mode */
  color: #fff; /* Αλλάξτε το χρώμα κειμένου για το Light Mode */
}
/* Reset some default styles */
html, body, h1, h2, p, ul, li {
  margin: 0;
  padding: 0;
}

/* Set box-sizing to border-box for all elements */
* {
  box-sizing: border-box;
}

html {
  font-family: "Poppins", sans-serif;
overflow: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  accent-color: #602bff;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
}

/* Updated styles for the mobile menu */
.menu-icon {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  z-index: 3;
}

.bar {
  display: block;
  height: 26px;
  width: 32px;
  position: absolute;
  top: 17px;
  left: 20px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

body.light-mode .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: #0e2431;
}

body.dark-mode .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: #ffffff;
}

.bar .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.bar .line2 {
  transition: transform 0.2s ease-in-out;
}

.bar .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

.menu-icon.open .bar .line1 {
  transform: rotate(45deg);
}

.menu-icon.open .bar .line2 {
  transform: scaleY(0);
}

.menu-icon.open .bar .line3 {
  transform: rotate(-45deg);
}

.navbar {
  background: #da0707(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.bi-person-plus-fill{
  margin-right: 5px;
}
.bi-speedometer{
  margin-right: 5px;
}
.navlist {
  display: flex;
 
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 15px;
  margin-left: auto;
  animation: fadeInNavLinks 0.9s ease;

}
@keyframes fadeInNavLinks {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply the animation to the dropdown */
body .nav-links.open {
  animation: fadeInNavLinks 0.9s ease;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
 
  background-color: #08AEEA;
  background-image: linear-gradient(0deg, #08AEEA 0%, #2AF598 100%);
  border-radius: 5px;
}
/* body.dark-mode .nav-links.open {
  background-color: #17546d;
} */

body.dark-mode .nav-links.open {
  animation: fadeInNavLinks 0.9s ease;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 45px;
  left: 0;
  width: 100%;
  height: 3500%;
  background-color: #17546d;
  border-radius: 5px; /* Adjust the value to your preference */
}
.nav-links li {
  margin-right: 0;
}

body.light-mode .nav-links a {
  text-decoration: none;
  animation: fadeInNavLinks 0.9s ease;

  color: #fdfbfa;
  border: 2px solid transparent;
  border-radius: 30px;
  padding: 10px 15px;
  transition: border 0.3s ease, background 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
  font-weight: bolder;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.479);
    text-decoration: none; /* Remove underline */
}
body.dark-mode .nav-links a {
  animation: fadeInNavLinks 0.9s ease;

  text-decoration: none;
  color: #ffffff;
  border: 2px solid transparent;
  border-radius: 30px;
  padding: 10px 15px;
  transition: border 0.3s ease, background 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
  font-weight: bolder;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.479);
  text-decoration: none; /* Remove underline */
}

body.light-mode .nav-links a:hover {
  border-color: #08e45c;
  background: transparent;
  color: #000000;
}
body.dark-mode .nav-links a:hover {
  border-color: #0581e6;
  background: transparent;
  color: #ffffff;
}

body.light-mode .nav-links a:active {
  background-color: #08e45c;
  color: #030303;
}
body.dark-mode .nav-links a:active {
  background-color: #0581e6;
  color: #fff;
}

.nav-links a.active {
  background-color: #0581e6;
  color: #fff;
}

/* Dropdown styles */
.admin-dropdown {

  position: relative;
  display: inline-block;
  transition: all 0.3s ease; /* Add transition for smooth animation */
}

.admin-dropdown .dropbtn {
  background-color: transparent;
  color: #f7f8f6;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 50px;
  transition: border 0.3s ease, background 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
}

/* .admin-dropdown .dropbtn:hover {
  border: 2px solid #33fd00;
  background: transparent;
  color: #fff;
} */

body.light-mode .admin-dropdown .dropbtn:active {
  background-color: #33fd00;
  color: #020202;
}
body.dark-mode .admin-dropdown .dropbtn:active {
  background-color: #33fd00;
  color: #011f0d;
}
.admin-dropdown-content {
  position: relative;
}

.arrow-container {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.arrow {
  color: #fff;
  font-size: 20px;
  transition: transform 0.3s ease;
}


.admin-dropdown-content {
  animation: fadeInNavLinks 0.9s ease;

  position: relative;
  margin-top: 14px; /* Προσαρμόστε την τιμή όπως επιθυμείτε */

}

body.light-mode .arrow {
  position: absolute;
  top: -10px;
  left: 10px;
  display: inline-block;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #2AF598; /* Χρώμα που ταιριάζει με το υπόλοιπο περιεχόμενο */
  border-left: 10px solid transparent;
  border-bottom-color: rgb(#2AF598);
  content: '';
  margin-left: 10px;
}
body.dark-mode .arrow {
  position: absolute;
  top: -10px;
  left: 10px;
  display: inline-block;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #521eb1; /* Χρώμα που ταιριάζει με το υπόλοιπο περιεχόμενο */
  border-left: 10px solid transparent;
  border-bottom-color: rgb(#521eb1);
  content: '';
  margin-left: 10px;
}


body.light-mode .admin-dropdown-content {
  display: none;
  position: absolute;
  background-color: #08AEEA;
background-image: linear-gradient(0deg, #08AEEA 0%, #2AF598 100%);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
  top: 40px;
  left: 0;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}
body.dark-mode .admin-dropdown-content {
  display: none;
  position: absolute;
  background-image: linear-gradient(to right top, #7f34d1, #6929c1, #521eb1, #3a14a2, #1b0992);  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
  top: 40px;
  left: 0;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.admin-dropdown:hover .admin-dropdown-content {
  display: block;
  opacity: 1;
  transform: translateY(0);
}

.admin-dropdown .admin-dropdown-content a {
  color: #fff;
  padding: 12px 20px;
  display: block;
  text-decoration: none;
  transition: background 0.3s ease, color 0.3s ease, opacity 0.3s ease, transform 0.3s ease;
  opacity: 0;
  transform: translateY(-10px);
}

.admin-dropdown:hover .admin-dropdown-content a {
  opacity: 1;
  transform: translateY(0);
}

/* Προσθήκη του animation που θέλετε να διατηρήσετε */
.dropdown-content li {
  transition: background 0.3s ease, color 0.3s ease;
  opacity: 0;
  transform: translateY(-10px);
}

.dropdown-content li:hover {
  background: #1d1f24;
}

.dropdown-content li a {
  display: block;
  padding: 0.7em 0.5em;
  color: #fff;
  margin: 0.1em 0;
  text-decoration: none;
}
.nav-links.open  li {
  /* margin-bottom: 600px; */
}
body.light-mode .nav-links.open {
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 7050%;
  background-color: #08AEEA;
  background-image: linear-gradient(0deg, #08AEEA 0%, #2AF598 100%);  
  /* border-radius: 5px; /* Adjust the value to your preference */
  /* border: 3px solid #000000; */

}
body.dark-mode .nav-links.open {
  animation: fadeInNavLinks 0.9s ease;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 7000%;
  background-image: linear-gradient(to right top, #051937, #072561, #2b2d8a, #5c2caf, #9413cd);
  /* border-radius: 5px; Adjust the value to your preference */
  /* border: 2px solid #ffffff; */
  /* border-radius: 20px; */
}



/* Icons */
.icon {
  font-size: 1.25em;
  margin-right: 20px;
}
.logout-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 45px;
  height: 45px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: 0.3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: rgb(236, 59, 59);
  padding: 0;
  margin-right: 10px;
  will-change: transform, width;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

/* plus sign */
.logout-button .sign {
  width: 100%;
  transition-duration: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logout-button .sign svg {
  width: 17px;
}

.logout-button .sign svg path {
  fill: white;
}
/* text */
.logout-button .text {
  position: absolute;
  right: 0%;
  width: 0%;
  opacity: 0;
  color: white;
  font-size: 1.2em;
  font-weight: 600;
  transition-duration: 0.3s;
}
/* hover effect on button width */
.logout-button:hover {
  width: 125px;
  border-radius: 40px;
  transition-duration: 0.3s;
  will-change: transform, width;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.logout-button:hover .sign {
  width: 30%;
  transition-duration: 0.3s;
  padding-left: 20px;
}
/* hover effect button's text */
.logout-button:hover .text {
  opacity: 1;
  width: 70%;
  transition-duration: 0.3s;
  padding-right: 10px;
}
/* button click effect*/
.logout-button:active {
  transform: translate(2px, 2px);
}

[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
  opacity: 0;
  pointer-events: none;
}

.dark-light:checked + label,
.dark-light:not(:checked) + label {
  position: fixed;
  top: 40px;
  right: 40px;
  z-index: 20000;
  display: block;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  cursor: pointer;
  transition: all 200ms linear;
  box-shadow: 0 0 25px rgba(255, 235, 167, 0.45);
}

.dark-light:checked + label {
  transform: rotate(360deg);
}

.dark-light:checked + label:after,
.dark-light:not(:checked) + label:after {
  position: absolute;
  content: '';
  top: 1px;
  left: 1px;
  overflow: auto;
  z-index: 2;
  display: block;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  background-color: #102770;
  background-image: url('https://assets.codepen.io/1462889/moon.svg');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 200ms linear;
  opacity: 0;
}

.dark-light:checked + label:after {
  opacity: 1;
}

.dark-light:checked + label:before,
.dark-light:not(:checked) + label:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  overflow: auto;
  z-index: 1;
  display: block;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  background-color: #48dbfb;
  background-image: url('https://assets.codepen.io/1462889/sun.svg');
  background-size: 25px 25px;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 200ms linear;
}

.dark-light:checked + label:before {
  background-color: #000;
}
.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }
  
  .loading-screen.visible {
    visibility: visible;
    opacity: 1;
  }
  
  .loading-spinner {
    font-size: 3rem;
    color: #06da93;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  

/* Media query for small screens */
/* Media query for small screens */
@media only screen and (max-width: 700px) {
  .menu-icon {
    display: flex;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
  }

  .bar {
    display: block;
    height: 26px;
    width: 32px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: #0e2431;
    transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
  }

  .line1 {
    transform-origin: 0% 0%;
  }

  .line2 {
    transition: transform 0.2s ease-in-out;
  }

  .line3 {
    transform-origin: 0% 100%;
  }


  .nav-links.open {
    display: flex;
    flex-direction: column;
    align-items: center; /* Align items vertically at the center */
    position: absolute;
    top: 100%; /* Adjust the top value if needed */
    left: 0;
    width: 100%;
    background-color: #fff; /* Adjust background color if needed */
    border: 1px solid #ddd; /* Add a border if needed */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a box shadow if needed */
  }
  
  .nav-links.open li {
    margin-bottom: 20px; /* Adjust the margin-bottom value as needed */
    font-size: 18px; /* Adjust the font size as needed */
    /* Add any other styles for the elements when the navigation is open */
  }
  .nav-links.open li:first-child {
    margin-top: 300px; 
    font-size: px; /* Adjust the font size as needed */
    /* Adjust the margin-top value for the first li element */
  }
  
  .admin-dropdown .dropdown-content {
    animation: fadeInNavLinks 0.9s ease;

    border-radius: 0;
  }

  .menu-icon.open .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .menu-icon.open .line2 {
    transform: scaleY(0);
  }

  .menu-icon.open .line3 {
    transform: rotate(45deg) translate(-5px, -6px);
  }
}
/* Media query for screens 601px and larger */
.nav-links {
  display: none;
}

.menu-icon {
  display: block;
}

.navbar {
  display: flex;
}

/* Media query για οθόνες 601px και μεγαλύτερες */
@media only screen and (min-width: 701px) {
  .menu-icon {
    display: none;
  }

  .nav-links {
    display: flex;
  }
}