.admin-container {
  max-width: 2000px;
  margin: 0 auto;
  padding: 20px;

  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: auto; 

}

.admin-header {
  text-align: center;
  margin-bottom: 20px;
}

.admin-content {
  display: flex;
  justify-content: space-between;
  
}
 body.light-mode .admin-par{
  color:#333;
 }
 body.dark-mode .admin-par{
  color:#ffffff;
 }
body.light-mode .admin-functions,
.admin-users {
  flex: 1;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-right: 20px;
  background-color: #ffffff;
}
body.dark-mode
.admin-users {
  flex: 1;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-right: 20px;
  background-color: #271f1f60;
}
body.dark-mode .admin-functions {
  flex: 1;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-right: 20px;
  background-color: #271f1f60;
}

body.light-mode .admin-functions h2,
.admin-users h2 {
  color: #333;
}
body.light-mode h3{
  color: #333;
}

body.dark-mode h3{
  color: #ffffff;
}
body.dark-mode .admin-functions h2,
.admin-users h2 {
  color: #ffffff;
}

body.dark-mode .admin-users h2 {
  color: #ffffff;
}

.function-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.function-item span {
  font-size: 24px;
  margin-right: 12px;
}

button {
  background-color: #d9534f;
  color: #fff;
  padding: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #c9302c;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

body.light-mode th, td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}


th {
  background-color: #ffffff;
}


@media (max-width: 800px) {
  .admin-content {
    flex-direction: column; 
  }

  .admin-functions,
  .admin-users {
    flex: 1;
    margin-right: 0;
    margin-bottom: 20px; 
  }
}

.admin-header {
  text-align: center;
  margin-bottom: 20px;
}

.admin-content {
  display: flex;
  justify-content: space-between;
}

.admin-par {
  color: #333;
}

.admin-functions,
.admin-users {
  flex: 1;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-right: 20px;
 
}

.admin-functions h2,
.admin-users h2 {
  color: #333;
}

.function-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.function-item span {
  font-size: 24px;
  margin-right: 12px;
}

button {
  background-color: #d9534f;
  color: #fff;
  padding: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #c9302c;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

