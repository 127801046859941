body {
    margin: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .home-container {
    padding: 20px;
    /* background: linear-gradient(to right, #0a9768, #0a0c81);  */
    color: #ffffff;
    overflow: hidden;
  }
  
  body.dark-mode .promo-banner {
    background: linear-gradient(to right, #25ccf7, #4834d4);
    color: #ffffff;
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }
  body.light-mode .promo-banner {
    background: linear-gradient(to right, #c2460c, #b40606);
    color: #ffffff;
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }
  .promo-banner h1 {
    font-size: 2em;
    margin-bottom: 10px;
    color: #ffffff;
  }
  
  .home-content {
    display: flex;
    flex-wrap: wrap;
  }
  
  body.dark-mode .home-section {
    flex: 1;
    margin: 10px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    background: linear-gradient(to right, #25ccf7, #4834d4);
    overflow: hidden;
    position: relative;
  }
  body.light-mode .home-section {
    flex: 1;
    margin: 10px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    background: rgba( 255, 255, 255, 0.45 );
box-shadow: 0 8px 32px 0 rgba(3, 4, 5, 0.281);
backdrop-filter: blur( 8px );
-webkit-backdrop-filter: blur( 8px );
border-radius: 10px;


    overflow: hidden;
    position: relative;
  }
  body.light-mode .promo-banner_alt {
    background-image: linear-gradient(180deg, #2af598 0%, #009efd 100%);
    color: #ffffff;
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 0 20px rgb(0, 0, 0);
  }
  .home-widget {
    margin-top: 20px;
  }
  
  .home-instructions {
    margin-top: 20px;
    text-align: center;

  }
  
  .home-instructions p {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  /* AOS Library Animations */
  [data-aos] {
    opacity: 0;
    transition-duration: 1s;
  }
  
  [data-aos].aos-animate {
    opacity: 1;
  }
  body {
    margin: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .home-container {
    padding: 20px;
    /* background: linear-gradient(to right, #0a9768, #0a0c81);  */
    color: #ffffff;
    overflow: hidden;
  }

  body.dark-mode .promo-banner {
    background-image: linear-gradient(to right top, #132b9b, #005bbf, #0082cb, #00a5c7, #00c5c0, #00ccc6, #00d2cd, #00d9d3, #00c6ef, #00aeff, #008eff, #655ffb);    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }
  body.light-mode .promo-banner {
    background-image: linear-gradient(to right, #6bd1a4, #00c0b7, #00acca, #0094d2, #0078ca, #0079cd, #0079d1, #007ad4, #009ae7, #00b7ec, #00d2e7, #00ebdc);
    color: #ffffff;
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }
  .promo-banner h1 {
    font-size: 2em;
    margin-bottom: 10px;
    color: #ffffff;
  }
  
  .home-content {
    display: flex;
    flex-wrap: wrap;
  }
  
  body.dark-mode .home-section {
    flex: 1;
    margin: 10px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    background: rgba(59, 57, 57, 0.45);
box-shadow: 0 8px 32px 0 rgba(3, 4, 5, 0.158);
backdrop-filter: blur( 8px );
-webkit-backdrop-filter: blur( 8px );
border-radius: 10px;
-webkit-box-shadow:0px 0px 113px 29px rgba(230, 232, 229, 0.281);
-moz-box-shadow: 0px 0px 113px 29px rgba(230, 232, 229, 0.219);
box-shadow: 0px 0px 113px 29px rgba(230, 232, 229, 0.253);

    overflow: hidden;
    position: relative;
  }
  body.light-mode .home-section {
    flex: 1;
    margin: 10px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    background: rgba( 255, 255, 255, 0.45 );
box-shadow: 0 8px 32px 0 rgba(3, 4, 5, 0.158);
backdrop-filter: blur( 8px );
-webkit-backdrop-filter: blur( 8px );
border-radius: 10px;
-webkit-box-shadow:0px 0px 113px 29px rgba(230, 232, 229, 0.233);
-moz-box-shadow: 0px 0px 113px 29px rgba(230, 232, 229, 0.363);
box-shadow: 0px 0px 113px 29px rgba(230, 232, 229, 0.089);


    overflow: hidden;
    position: relative;
  }
  .home-section:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2); /* Adjust the amount you want to move on hover */
  }
  body.light-mode .promo-banner_alt {
    background-image: linear-gradient(180deg, #2af598 0%, #009efd 100%);
    color: #ffffff;
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 0 20px rgb(0, 0, 0);
  }
  .home-widget {
    margin-top: 20px;
  }
  
  .home-instructions {
    margin-top: 20px;
    text-align: center;

  }
  
  .home-instructions p {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  /* AOS Library Animations */
  [data-aos] {
    opacity: 0;
    transition-duration: 1s;
  }
  
  [data-aos].aos-animate {
    opacity: 1;
  }
  @media screen and (max-width: 850px) {
    .home-content {
      flex-direction: column; /* Change flex direction to column */
    }
  
    body.dark-mode .home-section,
    body.light-mode .home-section {
      flex: 1;
      margin: 10px;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      overflow: hidden;
      position: relative;
    }
  }