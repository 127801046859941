



body {
    font-family: 'Poppins', sans-serif;
    background-color: #FAF9FF;
}

.Wrong-notification {
    position: fixed;
    top: 35px; /* Αλλάξτε την τιμή εδώ για να προσαρμόσετε το ύψος προς τα κάτω */
    right: 90px; /* Αλλάξτε την τιμή εδώ για να προσαρμόσετε τη θέση προς τα αριστερά */
    /* Οι υπόλοιποι κανόνες παραμένουν ίδιοι */
    width: max-content;
    border-radius: 6px;
    background-color:  #24301f;
    color: #f30202;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    transform: translateY(30px);
    opacity: 0;
    visibility: hidden;
    animation: fade-in 3s linear;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;

  }
  
  

.Wrong-notification__icon {
    height: 26px;
    width: 26px;
    margin-right: 4px;
    color: #f30202;
}

.Wrong-notification__body {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 8px;
}

.Wrong-notification__progress {
    position: absolute;
    left: 4px;
    bottom: 4px;
    width: calc(100% - 8px);
    height: 3px;
    transform: scaleX(0);
    transform-origin: left;
    background: linear-gradient(
        to right, 
        #24301f,  
        #f30202
    );
    border-radius: inherit;
    animation: progress 2.5s 0.3s linear;
}

@keyframes fade-in {
    5% {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
    95% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes progress {
    to {
        transform: scaleX(1);
    }
}