@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.login-reset {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.login-body {
  background: transparent;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 90px 1fr;
  font-family: "Poppins", sans-serif;
}

.login-box-container {
  margin-bottom: 15vh;
  padding-top: 5vh;
}

.login-form-container {
  width: 400px;
  border: 2px solid rgb(50, 192, 192);
  height: 600px;
  margin: 10vh auto;
  border-radius: 20px;
  box-shadow: 2px 2px 100px #00000057, -2px -2px 100px #0a022288;
  backdrop-filter: blur(3px);
  padding-bottom: 2rem;
  -webkit-box-shadow:0px 0px 113px 12px rgba(81,255,46,0.47);
-moz-box-shadow: 0px 0px 113px 12px rgba(81,255,46,0.47);
box-shadow: 0px 0px 113px 12px rgba(81,255,46,0.47);
}
.spotify-button {
  padding: 0.5rem 2rem;

  display: flex;
  background-color: #72d812;
  height: 35PX;
  width: 220px;
  color: #ffffff;
  padding: 0.5rem 1.4rem;
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  border-radius: 15px;
  gap: 0.75rem;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: .6s ease;
  margin-top: 15px;
  margin-top: 15px;
}

.spotify-button svg {
  height: 22px;
}

.spotify-button:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  background-color: #7fd31f;
  color: #ffffff;
}
/* Import a modern font from Google Fonts */

/* Apply the modern font to your title */
body.light-mode .login-heading {
  text-align: center;
  color: #f5f4f4;
  letter-spacing: 2px;
  font-size: 35px;
  font-family: 'Poppins', sans-serif; /* Use the imported font */
  text-shadow: rgba(0, 255, 191, 1) 0px 0px 10px; /* Adjusted text shadow */

  /* Add a neon sign flicker animation */
  animation: flicker 1s infinite alternate;
}

/* Define the neon sign flicker animation keyframes */
@keyframes flicker {
  0% {
    opacity: 0.5;
    text-shadow: rgba(0, 255, 191, 1) 0px 0px 20px; /* Increased shadow size */
  }
  100% {
    opacity: 1;
    text-shadow: rgba(0, 255, 191, 1) 0px 0px 10px; /* Adjusted shadow size */
  }
}
body.dark-mode .login-heading {
  text-align: center;
  padding: 1rem 0.4rem;
  color: #f5f4f4;
  letter-spacing: 2px;
  font-size: 40px;
  font-family: 'Poppins', sans-serif; /* Use the imported font */
  text-shadow: rgba(0, 255, 191, 1) 0px 0px 10px; /* Adjusted text shadow */

  /* Add a neon sign flicker animation */
  animation: flicker 1s infinite alternate;

}
.coolinput {
  display: flex;
  flex-direction: column;
  width: fit-content;
  position: static;
  max-width: 240px;
  width: 500px;
 margin-left: 80px;
}

.coolinput label.text {
  font-size: 0.75rem;
  color: #06c0a1;
  font-weight: 700;
  position: relative;
  top: 0.5rem;
  margin: 0 0 0 7px;
  padding: 0 3px;
  background: #e8e8e8;
  width: fit-content;
  border-radius: 8px;
  border: 2px #06c0a1 solid;
  font-family: 'Poppins', sans-serif; /* Use the same font as the rest of the text */


}


.coolinput input[type=text].input {
  padding: 11px 10px;
  font-size: 0.75rem;
  border: 2px #06c0a1 solid;
  border-radius: 10px;
  background: #e8e8e8;
  font-family: 'Poppins', sans-serif; /* Use the same font as the rest of the text */

}
.coolinput input[type=password].input {
  padding: 11px 10px;
  font-size: 0.75rem;
  border: 2px #06c0a1 solid;
  border-radius: 10px;
  background: #e8e8e8;
  -moz-text-security: disc;
  font-family: 'Poppins', sans-serif;
}
.coolinput input[type=text].input:focus {
  outline: none;
}

.login-username-container,
.login-password-container {
  margin: 1rem 1rem;
  align-items: center;
}

.login-username-container input,
.login-password-container input {
  padding: 10px;
  margin-bottom: 10px;
  width: calc(100% - 20px);
  box-sizing: border-box;
  border: 1px solid #0fc9a1;
  border-radius: 15px; /* Adjusted border radius */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.623);
  background-color: #fff; /* Initial white background color */
  color: #00ffb3; /* Initial text color */
  transition: box-shadow 0.3s, background-color 0.3s;
  font-family: 'Poppins', sans-serif; /* Use the same font as the rest of the text */
  margin-left: 12px;
}

.login-username-container input::placeholder,
.login-password-container input::placeholder {
  color: #00ffc8;
}

.login-username-container input:focus,
.login-password-container input:focus {
  outline: none;
  box-shadow: 0 0 12px rgba(52, 152, 219, 0.4);
  background-color: #19daa0; /* Blue background color on focus */
  color: #ffffff; /* White text color on focus */
}

.login-username-container input:focus::placeholder,
.login-password-container input:focus::placeholder {
  color: #ffffffb0; /* Slightly transparent white placeholder color on focus */
}

.login-password-container p {
  color: #12b982;
  margin-top: 0.5rem;
}

.login-icon {
  padding-right: 0.5rem;
  color: #ddd;
}

.login-logo {
  color: white;
  font-size: 30px;
  text-decoration: none;
  font-weight: 600;
  cursor: default;
}

.login-buttons-container {
  display: flex;
  flex-direction: column; /* Display buttons in a column */
  align-items: center; /* Center items horizontally */
  margin-top: 2rem; /* Add margin at the top of the buttons container */
}

.login-remember-container {
  display: inline-flex;
  align-items: center;
  font-weight: bold;
}

.login-checkbox {
  margin-right: 0.5rem;
}

.login-a {
  
  color: #22d4bc;
  transition: all 0.3s;
}

.login-a:hover {
  opacity: 0.8;
}
.login-btn-box {
  display: flex;
  flex-direction: column; /* Display buttons in a column */
  align-items: center; /* Center items horizontally */
  /* margin-bottom: 1rem; Add margin between buttons */
  
}
.login-btn-box a {
  padding: 0.5rem 3rem;
  border-radius: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease, color 0.3s ease;
  background-color: #22d4bc;
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Poppins', sans-serif; /* Use the same font as the rest of the text */
  text-decoration: none;
  display: inline-block;
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin-bottom: 1rem;
}

.login-btn-box a:before {
  content: "";
  position: absolute;
  background: #fff;
  height: 100%;
  width: 0;
  top: 0;
  left: 50%;
  z-index: -1;
  transition: all 0.3s ease;
}

.login-btn-box a:hover:before {
  width: 100%;
  left: 0;
}

.login-btn-box a:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  background-color: #1a9a89;
  color: #fff;
}

.login-btn-box a:hover:before {
  background: #1a9a89; /* Adjust to match the hover background color */
}
.icon-container {
  position: absolute;
  right: 10px; /* Adjust the right offset as needed */
  top: 50%;
  transform: translateY(-50%);
}


.login-register-container {
  display: flex;
  justify-content: center;
  font-size: 0.75rem;
  margin-top: 2rem;
  font-weight: bold;
}
.or-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.2rem 0;
}

.or-text {
  margin: 0;
  padding: 0 10px; /* Προσαρμογή ανάλογα με την επιθυμία σας */
  color: #ffffff;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
}




.google-btn-box {
  text-align: center; /* Center the Google button text */
}

.google-button {
  padding: 10px;
  font-weight: bold;
  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  align-items: center;
  border: solid black 2px;
  outline: none;
  font-family: 'Poppins', sans-serif; 
  background-color: #fff;
  color: #000;
  width: 220px;
  height: 35px;
}

.svg {
  left: 30px;
  height: 20px;
  margin-right: 15px;
}

.google-button .text {
  z-index: 10;
  font-size: 14px;
}

.google-button:hover .text {
  animation: text forwards 0.3s;
  /*color: white;*/
}

@keyframes text {
  from {
    color: black;
  }

  to {
    color: white;
  }
}

.svg {
  z-index: 6;
}

.google-button:hover::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 9%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  opacity: 0;
  border-radius: 300px;
  animation: wave1 2.5s ease-in-out forwards;
  /* color:#fff */
}

.google-button:hover::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 9%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  opacity: 0;
  border-radius: 300px;
  animation: wave2 2.5s ease-in-out forwards;
}

@keyframes wave1 {
  0% {
    z-index: 1;
    background: #EB4335;
    width: 0;
    height: 0;
    opacity: 1;
  }

  1% {
    z-index: 1;
    background: #EB4335;
    width: 0;
    height: 0;
    opacity: 1;
  }

  25% {
    z-index: 1;
    background: #EB4335;
    width: 800px;
    height: 800px;
    opacity: 1;
  }

  26% {
    z-index: 3;
    background: #34A853;
    width: 0;
    height: 0;
    opacity: 1;
  }

  50% {
    z-index: 3;
    background: #34A853;
    width: 800px;
    height: 800px;
    opacity: 1;
  }

  70% {
    z-index: 3;
    background: #34A853;
    width: 800px;
    height: 800px;
    opacity: 1;
  }

  100% {
    z-index: 3;
    background: #34A853;
    width: 800px;
    height: 800px;
    opacity: 1;
  }
}

@keyframes wave2 {
  0% {
    z-index: 2;
    background: #FBBC05;
    width: 0;
    height: 0;
    opacity: 1;
  }

  11% {
    z-index: 2;
    background: #FBBC05;
    width: 0;
    height: 0;
    opacity: 1;
  }

  35% {
    z-index: 2;
    background: #FBBC05;
    width: 800px;
    height: 800px;
    opacity: 1;
  }

  39% {
    z-index: 2;
    background: #FBBC05;
    width: 800px;
    height: 800px;
    opacity: 1;
  }

  40% {
    z-index: 4;
    background: #4285F4;
    width: 0;
    height: 0;
    opacity: 1;
  }

  64% {
    z-index: 4;
    background: #4285F4;
    width: 800px;
    height: 800px;
    opacity: 1;
  }

  100% {
    z-index: 4;
    background: #4285F4;
    width: 800px;
    height: 800px;
    opacity: 1;
  }
}

.google-button:hover .red {
  animation: disappear 0.1s forwards;
  animation-delay: 0.1s;
}

.google-button:hover .yellow {
  animation: disappear 0.1s forwards;
  animation-delay: 0.3s;
}

.goole-button:hover .green {
  animation: disappear 0.1s forwards;
  animation-delay: 0.7s;
}

.google-button:hover .blue {
  animation: disappear 0.1s forwards;
  animation-delay: 1.1s;
}

@keyframes disappear {
  from {
    filter: brightness(1);
  }

  to {
    filter: brightness(100);
  }
}

.apple-button {
  padding: 0.5rem 2rem;

  display: flex;
  background-color: #000;
  height: 35PX;
  width: 220px;
  color: #ffffff;
  padding: 0.5rem 1.4rem;
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  border-radius: 15px;
  gap: 0.75rem;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: .6s ease;
  margin-top: 15px;
  margin-top: 15px;
}

.apple-button svg {
  height: 25px;
}

.apple-button:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  background-color: #000000;
  color: #ffffff;
}

