@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: transparent;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.register-box {
  background: transparent;
  display: grid;
  grid-template-columns: 2fr;
  grid-template-rows: 90px 1fr;
  font-family: "Poppins", sans-serif;
  margin-bottom: 150%;
}
 .coolinput-register{
  display: flex;
  flex-direction: column;
  width: fit-content;
  position: static;
  max-width: 240px;
  width: 500px;
 margin-left: 100px;
} 


.coolinput-register label.text {
  font-size: 0.75rem;
  color: #06c0a1;
  font-weight: 700;
  position: relative;
  top: 0.5rem;
  margin: 0 0 0 7px;
  padding: 0 3px;
  background: #e8e8e8;
  width: fit-content;
  border-radius: 8px;
  border: 2px #06c0a1 solid;
  font-family: 'Poppins', sans-serif; /* Use the same font as the rest of the text */


}


.coolinput-register input[type=text].input {
  padding: 11px 10px;
  font-size: 0.75rem;
  border: 2px #06c0a1 solid;
  border-radius: 10px;
  background: #e8e8e8;
  font-family: 'Poppins', sans-serif; /* Use the same font as the rest of the text */

}
.coolinput-register input[type=password].input {
  padding: 11px 10px;
  font-size: 0.75rem;
  border: 2px #06c0a1 solid;
  border-radius: 10px;
  background: #e8e8e8;
  -moz-text-security: disc;
  font-family: 'Poppins', sans-serif;
}
.coolinput-register input[type=text].input:focus {
  outline: none;
}


.register-form {
/*margin-bottom: 200%;*/
  width: 450px;
  height: 725px;
  border-radius: 20px;
  box-shadow: 2px 2px 100px #00000057, -2px -2px 100px #0a022288;
  backdrop-filter: blur(3px);
  border: 2px solid rgb(50, 192, 192);
  -webkit-box-shadow:0px 0px 113px 12px rgba(81,255,46,0.47);
-moz-box-shadow: 0px 0px 113px 12px rgba(81,255,46,0.47);
box-shadow: 0px 0px 113px 12px rgba(81,255,46,0.47);
}
.coolframe-box{
  margin-bottom: 20px;
}
.email-icon{
  margin-left: 250px;
position: fixed;
top: 210px;
}
body.light-mode .register-heading {
  text-align: center;
  padding: 1rem 1rem;
  color: #ffffff;
  letter-spacing: 3px;
  font-size: 30px;
  font-style: bold;
}

body.dark-mode .register-heading {
  text-align: center;
  padding: 1rem 1rem;
  color: #ffffff;
  letter-spacing: 3px;
  font-size: 30px;
  font-style: bold;
}




.register-icon {
  padding-right: 0.5rem;
  color: #ddd;
}
.register-btn-box {
  display: flex;
  flex-direction: column; /* Display buttons in a column */
  align-items: center; /* Center items horizontally */
  /* margin-bottom: 1rem; Add margin between buttons */
  
}
.register-btn-box a {
  padding: 0.5rem 3rem;
  border-radius: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease, color 0.3s ease;
  background-color: #22d4bc;
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Poppins', sans-serif; /* Use the same font as the rest of the text */
  text-decoration: none;
  display: inline-block;
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin-bottom: 0.875rem;
  margin-right: 500px;
}

.register-btn-box a:before {
  content: "";
  position: absolute;
  background: #fff;
  height: 100%;
  width: 0;
  top: 0;
  left: 50%;
  z-index: -1;
  transition: all 0.3s ease;
}

.register-btn-box a:hover:before {
  width: 100%;
  left: 0;
}

.register-btn-box a:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  background-color: #1a9a89;
  color: #fff;
}

.register-btn-box a:hover:before {
  background: #1a9a89; /* Adjust to match the hover background color */
}

.register-btn-box a:hover {
  opacity: 0.8;
}

.register-buttons-container {
  display: flex;
  justify-content: space-between;
  padding: 0 8.5rem;
  font-size: 0.75rem;
  margin-bottom: 1rem;
}

.register-register-container {
  display: flex;
  justify-content: center;
  font-size: 0.75rem;
  margin-bottom: 15rem;
  /* font-weight: bold; */
  color: #fff;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

/* Your existing styles here */

/* Media query for screens below 600px */
@media only screen and (max-width: 430px) {
  .navbar {
    padding: 10px 10px; /* Adjusted padding for smaller screens */
  }

  .register-box {
    margin-bottom: vh; /* Adjusted margin for smaller screens */
  }

  .register-form {
    width: 90%; /* 90% width for smaller screens */
    max-width: 600vh; /* Maximum width for smaller screens */
    height: 700px; /* Auto height for smaller screens */
    margin: 0 auto; /* Center the form on smaller screens */
  }

 

  .register-btn-box a {
    margin-right: 0; /* Removed the right margin for smaller screens */
  }
  
  .register-buttons-container {
    padding: 0 1rem; /* Adjusted padding for smaller screens */
  }

  .register-register-container {
    margin-bottom: 5rem; /* Adjusted margin for smaller screens */
  }
}
