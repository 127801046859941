/* admin.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.unique-h2 {
  text-align: center;
  color: #d8e2e9;
  font-size: 24px;
  margin-bottom: 17px;
}

body {
  display: grid;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
}

#main-content {
  width: 1300px;
  margin: 0 auto;
}


.frame-elements {
  display: grid;
  grid-template-rows: auto auto 1fr;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
}

.unique-frame-role {
  width: 4000px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  margin: 0 10px;
}

.unique-frame-role:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
}

.form {
  display: flex;
  justify-content: center;
}

body.light-mode .label-role {
  margin-bottom: 10px;
  color: #333;
}
body.dark-mode .label-role {
  margin-bottom: 10px;
  color: #ffffff;
}
.input-role {
  padding: 12px;
  margin-bottom: 15px;
  width: calc(100% - 24px);
  box-sizing: border-box;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f8f9fa;
  color: #495057;
  transition: box-shadow 0.3s, background-color 0.3s;
}

.input-role,
.button-role {
  background-color: transparent;
  border: 2px solid #3498db;
  color: #3498db;
  transition: background-color 0.3s, color 0.3s;

  font-family: 'Poppins', sans-serif;
}

.input-role:focus {
  outline: none;
  box-shadow: 0 0 12px rgba(52, 152, 219, 0.4);
}

.input-role:focus,
.button-role:hover {
  background-color: #3498db;
  color: #ffffffb0;
}
body .body.light-mode .headsytle{
  color: #000000;
}
body .body.light-mode .headstyle{
  color: #ffffff;
}
body.light-mode .input-container {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}
body.dark-mode .input-container {
  text-align: center;
  background-color: rgba(43, 35, 35, 0.8);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.button-role {
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.2s;
}

.button-create {
  background: linear-gradient(to right, #2eeb08, #92ec00);
}

.button-update {
  background: linear-gradient(to right, #00bcd4, #2196f3);
}

.button-show {
  background: linear-gradient(to right, #08f586, #06ad91);
}

.button-delete {
  background: linear-gradient(to right, #fd0000, #db6707);
}

.button-role:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
}

.button-role:active {
  transform: scale(0.95);
  box-shadow: 0 0 12px rgba(31, 97, 141, 0.4);
}

@media screen and (max-width: 1172px) {
  .unique-h2 {
    margin-top: 5%;
  }

  .unique-main-content {
    width: 50%;
  }

  .unique-frame-role {
    width: 40%;
    margin: 20px auto;
    align-items: center;
  }

  .form {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 35%;
    margin-top: 1%;
  }

  .button-role {
    width: 60%;
  }

  .label-role,
  .input-role {
    width: 60%;
  }
}
